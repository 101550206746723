@charset "utf-8";
@import "../mixin";

/*---------------------------------------------
styleguide
---------------------------------------------*/

// 指定するmarginの範囲を定義
$margin-min: 0;
$margin-max: 20;
@for $i from $margin-min through $margin-max {
    $num : $i * 5;
    .mt#{$num} { margin-top: #{$num}px !important; }
    .mr#{$num} { margin-right: #{$num}px !important; }
    .mb#{$num} { margin-bottom: #{$num}px !important; }
    .ml#{$num} { margin-left: #{$num}px !important; }
    .mt-#{$num} { margin-top: -#{$num}px !important; }
    .mr-#{$num} { margin-right: -#{$num}px !important; }
    .mb-#{$num} { margin-bottom: -#{$num}px !important; }
    .ml-#{$num} { margin-left: -#{$num}px !important; }
    .mt#{$num}_pc { @include PC{ margin-top: #{$num}px !important; };}
    .mr#{$num}_pc { @include PC{ margin-right: #{$num}px !important; };}
    .mb#{$num}_pc { @include PC{ margin-bottom: #{$num}px !important; };}
    .ml#{$num}_pc { @include PC{ margin-left: #{$num}px !important; };}
    .mt#{$num}_sp { @include SP{ margin-top: #{$num}px !important; };}
    .mr#{$num}_sp { @include SP{ margin-right: #{$num}px !important; }}
    .mb#{$num}_sp { @include SP{ margin-bottom: #{$num}px !important; }}
    .ml#{$num}_sp { @include SP{ margin-left: #{$num}px !important; }}
}

.ta-center{
    text-align: center !important;
    @include SP{
        text-align: left !important;
    };
}
.ta-left{text-align: left !important;}
.ta-right{text-align: right !important;}

.wrap{
    @include SP{
        display: flex;
        flex-direction: column;

        > .mv{
            order: 1;
        }

        > .local-nav{
            order: 3;
        }

        > .inner{
            order: 2;
            width: 100%;
        }
    };
}

.mv{
    height: 200px;
    position: relative;
    background-color: #093776;

    @include SP{
        height: 120px;
        margin-bottom: 60px;
        order: 1;
    };

    &_system, &_company, &_info, &_strength, &_seminar, &_login{
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: transparent;
        @include SP{
            height: 150px;
        };
    }

    &_system{
        background-image: url(/assets/images/system/mv.jpg);
    }
    &_company{
        background-image: url(../../images/company/mv.jpg);
    }
    &_info{
        background-image: url(../../images/info/mv.jpg);
    }
    &_strength{
        background-image: url(../../images/strength/mv.jpg);
    }
    &_seminar{
        background-image: url(../../images/seminar/mv.jpg);
    }
    &_login{
        background-image: url(../../images/login/mv.jpg);
    }
}

h2{
    font-size: 28px;
    letter-spacing: 0.04em;
    line-height: 1.25em;
    color: #FFF;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50px;
    // font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝","MS PMincho", serif;
    font-family: 'Noto Serif JP', serif;
    font-weight: bold;
    @include SP{
        font-size: 20px;
        text-align: center;
        background-color: #303030;
        left: 50%;
        transform: translateX(-50%);
        top: auto;
        bottom: -60px;
        height: 60px;
        padding-top: 18px;
        width: 100%;
    };
}

h3{
    font-size: 28px;
    line-height: 1.642857143em;
    text-align: center;
    position: relative;
    padding: 40px 0 0;
    font-family: 'Noto Sans JP', sans-serif;
    @include SP{
        font-size: 24px;
        padding: 35px 0 0;
    };

    &:before{
        content: '';
        width: 4px;
        height: 30px;
        background-color: #0269b2;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        @include SP{
            width: 2px;
        };
    }
}

main{
    background-color: #FFF;
    padding: 30px;
    margin: 50px 0 20px;
    @include SP{
        margin-top: 20px;
        padding: 15px 20px 20px;
    };
}

h3 ~ main{
    margin-top: 20px;
}

h4{
    font-size: 24px;
    line-height: 1.7em;
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    font-family: 'Noto Sans JP', sans-serif;
    @include SP{
        font-size: 20px;
        line-height: 1.1245em;
    };

    small{
        font-size: 15px;
        margin-left: 15px;
        @include SP{
            font-size: 13px;
            margin-left: 8px;
        };
    }

    &:before{
        content: '';
        width: 20px;
        height: 20px;
        border: #0269b2 solid 4px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 10px;
        @include SP{
            top: 2px;
        };
    }
}

h5{
    font-size: 18px;
    line-height: 1.388888889em;
    font-weight: bold;
    padding: 12px 15px;
    margin: 20px 0;
    position: relative;
    border: {
        top:  #0269b2 solid 2px;
        bottom: rgba(#818182, .5) solid 2px;
    }
    @include ie{
        padding: 15px 15px 9px;
    };

    &.typeB{
        display: flex;
        justify-content: space-between;
        @include SP{
            display: block;
        };

        small{
            font-weight: normal;
            font-size: 12px;
            flex-shrink: 0;

            @include SP{
                display: block;
            };
        }
    }

    @include SP{
        font-size: 16px;
        padding: 5px 10px;
        border: {
            top:  #0269b2 solid 1px;
            bottom: rgba(#818182, .5) solid 1px;
        }
    };
}

h6{
    font-size: 16px;
    line-height: 1.5625em;
    font-weight: bold;
    background-color: #d3dfee;
    padding: 7px 18px;
    position: relative;
    margin-bottom: 20px;
    @include SP{
        font-size: 14px;
        padding: 5px 10px;
    };
    @include ie{
        padding: 10px 18px 5px;
    };
    &:before{
        content: '';
        height: calc(100% - 20px);
        width: 4px;
        background-color: #0269b2;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        @include SP{
            height: calc(100% - 15px);
            width: 2px;
        };
    }
}

p{
    font-size: 14px;
    letter-spacing: 0.04em;
    line-height: 1.785714286em;
    text-align: justify;
    word-break: break-all;
    @include SP{
        font-size: 14px;
        line-height: 1.714285714em;
    };
}
.emphasis{
    padding-top: .4em;
    background-position: top left -1px;
    background-repeat: repeat-x;
    background-size: 1.05em .3em;
    background-image: radial-gradient(.10em .10em at center center,#000,#000 100%,transparent);
}

.blue-box{
    background-color: #D7ECFB;
    padding: 20px;
    border-radius: 10px;
}

section{
    margin-top: 30px;

    &:first-child{
        margin-top: 0;
    }
}

strong{
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5em;
    @include SP{
        font-size: 14px;
        line-height: 1.714285714em;
    };
}

ul.list{

    li{
        font-size: 14px;
        line-height: 1.857142857em;
        position: relative;
        padding-left: 15px;
        font-weight: 500;

        > .link{
            padding-left: 5px;
            margin: 0;
            &:before{
                content: none;
            }
        }

        &:before{
            content: '■';
            position: absolute;
            left: 0;
            color: #053193;
        }

        a{
            color: #235dac;
            border-bottom: #235dac solid 1px;
            transition: opacity .3s;
            @include PC{
              &:hover{
                  opacity: .7;
              }
            }

        }
    }

}

ol.order-list{
    counter-reset: li;

    li{
        position: relative;
        font-size: 14px;
        line-height: 1.857142857em;
        padding-left: 17px;
        font-weight: 500;

        p{
            font-weight: normal;
        }

        &:before{
            counter-increment: li;
            content: counter(li);
            margin-right: 5px;
            color: #053193;
            position: absolute;
            left: 0;
        }

        &:after{
            content: '.';
            position: absolute;
            top: 0;
            left: 9px;
            color: #053193;
        }
    }
}

.link{
    font-size: 14px;
    font-weight: bold;
    color: #0269b2;
    position: relative;
    padding-left: 25px;
    display: inline-block;
    line-height: 1.2em;
    @include SP{
        padding: 2px 0 2px 25px;
    }
    @include PC{
      &:hover{
          opacity: .7;
      }
    }


    &:before{
        content: '\f32b';
        font-family: 'Font Awesome 5 Free';
        font-weight: 300;
        color: #0269b2;
        font-size: 20px;
        position: absolute;
        top: 1px;
        left: 0;
        transition: none;
        @include SP{
            top: 2px;
        }
    }

    &-pdf{
        &:after{
            content: '\f1c1' !important;
            font-family: 'Font Awesome 5 Free';
            font-weight: 300;
            font-size: 16px;
            margin-left: 5px;
            color: #000;
        }
    }
    &[target="_blank"]{
        &:after{
            content: '\f14c';
            color: #000;
            font-family: 'Font Awesome 5 Free';
            font-weight: 300;
            font-size: 16px;
            margin-left: 5px;
            position: relative;
            top: 1px;
        }
    }
}

.btn{
    display: block;
    max-width: 250px;
    width: 100%;
    height: 40px;
    font-size: 14px;
    border: #235dac solid 1px;
    color: #235dac;
    text-align: center;
    padding-top: 9px;
    position: relative;
    margin: 0 auto;
    transition: all .3s;
    background-color: #FFF;
    line-height: 1.5em;
    font-family: 'Noto Sans JP', sans-serif;

    @include ie{
        padding-top: 8px;
    };


    @include SP{
        max-width: 202.5px;
        height: 37.5px;
        padding-top: 7px;
    };
    @include PC{
      &:hover{
          background-color: #235dac;
          color: #FFF;
          &:after{
              color: #FFF;
          }
      }
    }


    &:after{
        content: '\f105';
        font-family: 'Font Awesome 5 Free';
        font-weight: 300;
        font-size: 20px;
        color: #0269b2;
        position: absolute;
        top: 50%;
        right: 7px;
        transform: translateY(-50%);
    }
    &_arrow_left{
        &:after{
            content: '\f104';
            left: 7px;
            right: auto;

        }
    }
    &_grey{
        color: #818182;
        border-color: #818182;
        &:after{
            color: #818182;

        }
        @include PC{
          &:hover{
              background-color: #818182;
              color: #FFF;
              &:after{
                  color: #FFF;
              }
          }
        }

    }
}

.submit{
    display: block;
    max-width: 390px;
    height: 60px;
    width: 100%;
    background-color: #0269b2;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #FFF;
    position: relative;
    margin: 0 auto;
    transition: all .3s;
    @include PC{
      &:hover{
          background-color: #FFF;
          border: #0269b2 solid 1px;
          color: #0269b2;
          &:after{
              color: #0269b2;
          }
      }
    }


    @include ie{
        padding-top: 4px;
    };

    @include SP{
        max-width: 295px;
    };


    &:after{
        content: '\f105';
        font-family: 'Font Awesome 5 Free';
        font-weight: 300;
        font-size: 20px;
        color: #FFF;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
    }
}

.pagenav {
    display: flex;
    justify-content: center;

    &-btn{
        display: block;
        max-width: 160px;
        width: 100%;
        height: 40px;
        font-size: 14px;
        border: #0269b2 solid 1px;
        color: #0269b2;
        text-align: center;
        padding-top: 9px;
        position: relative;
        margin-left: 30px;
        transition: all .3s;

        @include ie{
            padding-top: 9px;
        };

        @include SP{
            margin-left: 10px;
            font-size: 14px;
        };
        @include PC{
          &:hover{
              background-color: #0269b2;
              color: #FFF;
              &:after{
                  color: #FFF;
              }
          }
        }


        &:nth-child(-n + 1){
            margin-left: 0;
        }
    }

    &-prev, &-next{
        @include SP{
            font-size: 0;
            width: 40px;
            flex-shrink: 0;
        };
        &:after{
            font-family: 'Font Awesome 5 Free';
            font-weight: 300;
            font-size: 20px;
            color: #0269b2;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            @include SP{
                font-size: 24px;
            };
        }
    }

    &-prev{
        &:after{
            content: '\f104';
            left: 10px;
            @include SP{
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            };
        }
    }
    &-next{
        &:after{
            content: '\f105';
            right: 10px;
            @include SP{
                right: 50%;
                transform: translateX(50%) translateY(-50%);
            };
        }
    }
    &-list{
        @include SP{
            max-width: inherit;
        };

        &:after{
            content: '\f0c9';
            font-family: 'Font Awesome 5 Free';
            font-weight: 300;
            font-size: 16px;
            position: absolute;
            color: #0269b2;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
        }
    }
}

.pagination{

    .wp-pagenavi{
        display: flex;
        justify-content: center;
        align-items: center;

        a, span{
            margin-right: 10px;
        }

        .previouspostslink, .nextpostslink{
            font-size: 0;
            width: 20px;
            height: 20px;
            border: #0269b2 solid 1px;
            position: relative;
            transition: all .3s;

            &:after{
                font-family: 'Font Awesome 5 Free';
                font-weight: 300;
                font-size: 20px;
                color: #0269b2;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }

            @include PC{
              &:hover{
                  background-color: #0269b2;
                  &:after{
                      color: #FFF;
                  }
              }
            }

        }

        .previouspostslink{
            @include ie{
                &:after{
                    top: 54%;
                    left: 45%;
                }
            };
            &:after{
                content: '\f104';
            }
            @include SP{
                margin-right: 20px;
            };
        }

        .nextpostslink{
            font-size: 0;
            margin-right: 0;
            @include ie{
                &:after{
                    top: 54%;
                    left: 55%;
                }
            };

            &:after{
                content: '\f105';
            }
            @include SP{
                margin-left: 10px;
            };
        }

        .page, .current, .first, .last{
            font-size: 16px;
            width: 40px;
            height: 40px;
            border: #0269b2 solid 1px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include ie{
                padding-top: 4px;
            };
        }

        .page, .first, .last{
            color: #0269b2;
            transition: all .3s;
            @include PC{
              &:hover{
                  background-color: #0269b2;
                  color: #FFF;
              }
            }

        }
        @include SP{
            .page:nth-of-type(4){
                display: none;
            }

        };

        .current{
            color: #FFF;
            background-color: #0269b2;
        }

        .extend{
            font-size: 16px;
            color: #0269b2;
        }

        //order
        .previouspostslink{ order:1; }
        .fist{ order:2; }
        .page, .current{ order:3; }
        .extend{ order:4; }
        .last{ order:5; }
        .nextpostslink{ order:6; }

    }
}

.column{
    display: flex;
    justify-content: center;
    @include SP{
        flex-wrap: wrap;
        justify-content: flex-start;
    };

    figure{
        margin-left: 30px;
        @include PC{
            &:nth-child(-n + 1){
                margin-left: 0;
            }
        };

        > div{
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                @include SP{
                    width: 100%;
                };
            }
        }

        figcaption{
            font-size: 14px;
            line-height: 1.857142857em;
        }
    }


    &.typeA{
        figure {
            @include SP{
                width: 100%;
                margin: 20px 0 0;

                &:nth-child(-n + 1){
                    margin-top: 0;
                }
            };
            > div{
                height: 240px;
                @include SP{
                    height: auto;
                    width: 100%;
                };
            }
        }
    }
    &.typeB{
        figure {
            @include SP{
                width: calc((100% - 20px) / 2);
                margin-left: 20px;
                margin-top: 20px;

                &:nth-child(2n + 1){
                    margin-left: 0;
                }

                &::nth-child(-n + 2){
                    margin-top: 0;
                }
            };
            > div{
                height: 180px;
                @include SP{
                    height: auto;
                };
            }
        }
    }
}

.box{
    display: flex;
    @include SP{
        flex-direction: column;
    };

    &_left{
        .box_txt{
            padding-left: 30px;
            @include SP{
                padding: 10px 0 0;
            };
        }
    }

    &_right{
        .box_txt{
            padding-right: 30px;
            @include SP{
                padding: 10px 0 0;
            };
        }
    }

    &_img{
        flex-shrink: 0;
        @include SP{
            width: 100%;
            order: 1;
            img{
                width: 100%;
            }
        };
    }

    &_txt{
        font-size: 14px;
        line-height: 1.857142857em;
        @include SP{
            order: 2;
            margin-top: 10px;
        };
    }
}

table{
    width: 100%;
    border-spacing: 0px;
    border: #cbcbcb solid 4px;

    a{
        text-decoration: underline;
    }

    body#news &{
        @include SP{
            height: auto !important;
            border-collapse: initial !important;
        };
    }

    &.auto, body#news &{
        th, td{
            width: auto;
        }

        @include SP{
            position: relative;
            overflow: auto;
            width: 100%;
            display: block;
            border: none;

            tbody{
                display: table;
                border: #cbcbcb solid 4px;

                tr{
                    display: table-row;
                }

                th, td{
                    white-space: nowrap;
                    width: auto;
                    display: table-cell;
                }
            }
        };
    }

    tr{
        vertical-align: top;

        &:nth-child(-n + 1){
            th, td {
                border-top: none;
            }
        }

        th, td{
            padding: 10px 20px 8px;
            font-size: 14px;
            line-height: 1.428571429em;

            @include ie{
                padding: 10px 20px 7px;
            };
        }

        th{
            width: 30%;
            font-weight: bold;
            flex-shrink: 0;
            background-color: rgba(#235dac, .1);
            text-align: left;
            border-top: #cbcbcb solid 1px;
            @include SP{
                background-color: rgba($color: #235dac, $alpha: .1);
            };
        }
        td{
            width: 70%;
            border-top: #cbcbcb solid 1px;
        }
    }

    @include SP{
        tr{
            display: block;

            &:nth-child(-n + 1) td{
                border-top: #cbcbcb solid 1px;
            }

            th, td{
                width: 100%;
                display: block;
                padding: {
                    left: 10px;
                    right: 10px;
                };
            }
        }
    };
}

.signbox{
    margin: 50px 0 0 auto;
    p{
        display: inline-block;
        margin-top: 20px;

        &:nth-child(1){
            margin-top: 0;
        }
    }
}

.p-txt{
    display: flex;
    justify-content: space-between;
    @include SP{
        display: block;
    };

    small{
        font-weight: normal;
        font-size: 12px;
        flex-shrink: 0;

        @include SP{
            display: block;
        };
    }
}
